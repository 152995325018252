import React from "react";
import styled from "styled-components";
import DailyPrayerTimes from "./DailyPrayerTimes";
import dateFormat from "dateformat";

const TemplateDetail = ({ service, type }) => {
  const now = new Date();
  return (
    <Container>
      <div style={{ marginTop: "10px" }}>
        <Grid>
          <div>
            <Title>{service.title}</Title>
            {type === "EVENT" || type === "NEWS" || type === "NOTIFICATIONS" ? (
              <p style={{ color: "#84ba40" }}>
                {dateFormat(service.eventDate || now, "mmmm dS, yyyy")}
              </p>
            ) : null}
            <Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: service.description,
                }}
              />
            </Content>
          </div>
          <div>
            <DailyPrayerTimes />
          </div>
        </Grid>
      </div>
    </Container>
  );
};

export default TemplateDetail;

const Grid = styled.div`
  max-width: 1024px;
  margin: 4% auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  padding-top: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Container = styled.div`
  background-color: #fff;
  padding: 4% 4%;
`;

const Title = styled.h3`
  margin: 0;
  text-transform: uppercase;
  font-size: 20px;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const Content = styled.p`
  marginbottom: 20px;
  font-size: 18px;
`;
